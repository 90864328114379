import {useQuery} from "@tanstack/react-query";
import {queryKey} from "../query/keys";
import {getAITaggingStatus} from "../../api/ai/getAITaggingStatus";

export const useGetAITaggingStatus = () => {
  return useQuery({
    queryKey: queryKey.aiTaggingStatus,
    queryFn: async () => {
      return getAITaggingStatus().then((res) => res.data);
    },
    refetchInterval: 1000 * 8,
  })
}
