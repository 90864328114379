import { Container, Title, Text, Group, Stack, Box } from '@mantine/core';
import { GradientButton } from 'src/components/GradientButton/GradientButtom';
import styles from './ErrorNotFound.module.scss';

import { FC } from 'react';

export const ErrorNotFound: FC = () => {
  return (
    <Container
      size="md"
      style={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Stack align="center" spacing="xl" style={{ color: '#fff' }}>
        <Group align="center">
          <Title
            order={1}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -71%)',
              fontSize: '13rem',
              fontWeight: 'bold',
              zIndex: -1,
            }}
            className={styles.errorNumber}
          >
            404
          </Title>
          <Title>You have found a secret place</Title>
        </Group>
        <Text size="lg" align="center">
          Unfortunately, this is only a 404. <br></br>You may have mistyped the address, or the page
          has been moved to another URL.
        </Text>
        <Box mt={40}>
          <GradientButton onClick={() => (document.location.href = '/')}>
            Take me back
          </GradientButton>
        </Box>
      </Stack>
    </Container>
  );
};
