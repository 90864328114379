import {checkAppBlock} from "../../api/widget/checkAppBlock";
import {countOfWidgetBlockStatusesErrors} from "../services/widgetBlockStatusesToText";
import {sendLivePreviewUsed} from "../eventLogger/eventLogger";
import {notifications} from "@mantine/notifications";
import {WidgetCardButtonError} from "../../pages/widgets/popups/WidgetCardButtonError";
import {useCheckInstallation} from "./useCheckInstallation";
import {useNavigate} from "react-router-dom";
import {useState} from "react";

export enum onPreviewError {
  navToInstallation = 'navToInstallation'
}

export const usePreviewWidget = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)
  const {
    mutate: checkInstallation,
    data: appInstalationStatus,
    mutateAsync: checkInstallationAsync
  } = useCheckInstallation()
  const onPreview = async (widget: {
    id: string;
    name: string;
  }, options?: {
    ifError?: onPreviewError[]
  }) => {
    setIsLoading(true)
    try {
      const [appBlock, widgetBlock] = await Promise.all([
        checkInstallationAsync(),
        checkAppBlock(widget.id)
      ])
      const {data} = widgetBlock;
      const errors = countOfWidgetBlockStatusesErrors(data.blockStatuses, appBlock.status === 'OK')

      if (errors === 0) {
        sendLivePreviewUsed()
        window.open(data.preview_url, '_blank');
      }

      if (errors > 0) {
        if (options?.ifError?.includes(onPreviewError.navToInstallation)) {
          navigate(`/widgets/edit/${widget.id}?defaultTab=installation`, {replace: true});
        }
        notifications.show({
          autoClose: 1000 * 11,
          title: `Alerts for ${widget?.name} widget`,
          message: <WidgetCardButtonError widgetTitle={widget?.name || ''} appEmbedDisabled={appBlock.status !== 'OK'}
                                          widgetBlockStatuses={data.blockStatuses}/>,
          color: 'red.0',
          styles: (theme) => ({
            root: {
              backgroundColor: theme.colors.greyDark[5]
            },
            title: {color: theme.colors.whitePrimary},
          })

        })
      }
    } catch (e) {
      console.error(e)
    }finally {
      setIsLoading(false)
    }
  }
  return {
    onPreview,
    isLoading
  }
}
