import {apiClient} from "../client";


export const putMessageAITaggingStatus = () => {
  const formData = new FormData();

  return apiClient.put('/bff/store-ai-tagging-status/status',formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
