import { useSelector } from 'react-redux';
import { Card } from '@mantine/core';


import { WidgetCardInnerTop } from './WidgetCardInnerTop/WidgetCardInnerTop'
import { WidgetCardInnerGallery } from './WidgetCardInnerGallery/WidgetCardInnerGallery'
import { WidgetCardInnerBottom } from './WidgetCardInnerBottom/WidgetCardInnerBottom';

import styles from './WidgetCardInner.module.scss'

import { FC } from 'react'
import { WCPreviewContainerProps } from '../WidgetCard.types';

export const WidgetCardInner: FC<WCPreviewContainerProps> = (props) => {
  const {
    id,
    title,
    isCreatedManually,
    type,
    videos,
    page,
    status,
    checked,
    allPages,
    onChecked,
    onDelete,
    onRename,
    onDuplicate,
    onPublish,
    onUnpublish,
    blockStatuses
  } = props;

  const source = (() => {
    const hasInstagram = videos.some(video => video.type === 'Instagram')
    const hasUpload = videos.some(video => video.type === 'Upload')

    if (hasInstagram && hasUpload) {
      return 'mixed'
    }

    if (hasInstagram) {
      return 'instagram'
    }

    if (hasUpload) {
      return 'upload'
    }

    return null
  })()

  return (
    <Card.Section className={styles.wrapper}>
     <WidgetCardInnerTop
        widgetId={id}
        widgetTitle={title}
        checked={checked}
        status={status}
        onChecked={onChecked}
        onDelete={onDelete}
        onRename={onRename}
        onDuplicate={onDuplicate}
        onPublish={onPublish}
        onUnpublish={onUnpublish}
        widgetBlockStatuses={blockStatuses}
      />

      <WidgetCardInnerGallery
        isCreatedManually={isCreatedManually}
        type={type}
        videos={videos}
      />

      <WidgetCardInnerBottom
        widgetId={id}
        blockStatuses={blockStatuses}
        source={source}
        status={status}
        page={page}
        isCreatedManually={isCreatedManually}
        allPages={allPages}
      />
    </Card.Section>
  )
}

export default WidgetCardInner
