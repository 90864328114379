import {QueryVideoFilter} from "../../api/video/getVideos";


export const queryKey = {
  videos: ({
    filter
  }: {
    filter: QueryVideoFilter;
  }) => ['videos', filter],
  videosCountPerFilter: ['videosCount'],
  taggedProductsWithVideoId: ({
    videoId
  }: {
    videoId: string;
  }) => ['taggedProductsWithVideoId', videoId],
  products: ['products'],
  allProductsWithTaggedVideo: ({
    videoId
  }: {
    videoId: string;
  }) => ['allProductsWithTaggedVideo', videoId],
  analytics: ['analytics'],
  widgetsInfinite: ['widgetsInfinite'],
  publishedWidgetsCount: ['publishedWidgetsCount'],
  widgetProducts: ['widgetProducts'],
  widgetProductDetails: ({widgetId}: {widgetId: string}) => ['widgetProductDetails', widgetId],
  singleWidget: ({widgetId}: {widgetId: string}) => ['singleWidget', widgetId],
  collections: ['collections'],
  widgetVideos: (filter: {
    collectionId?: string;
    productId?: string;
    widgetId?: string;
  }) => ['widgetVideos', filter],
  user: ['user'],
  maintenanceStatus: ['maintenanceStatus'],
  widgetPreviewProductFn: ['widgetPreviewProductFn'],
  widgetPreviewProduct: ['widgetPreviewProduct'],
  aiTaggingStatus: ['aiTaggingStatus'],
}
