import styles from './LoginScreenForFBReview.module.scss';
import FacebookLogin, { FacebookLoginClient } from '@greatsumini/react-facebook-login';
import { FacebookLoginButton } from '../LoginButton/FacebookLoginButton';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../User/selectors';

import { userActions } from '../../User/store';
import React, { FC } from 'react';
import { patchMe } from 'src/api/me/patchMe';
import { Box, Progress,  } from '@mantine/core';

import {useOnSuccessMetaConnected} from "../../../shared/hooks/useOnSuccessMetaConnected";
import {useIsAnyVideo} from "../../../shared/hooks/useIsAnyVideo";
import {useGetUser} from "../../../shared/hooks/tanstackQuery/useGetUser";
import {usePatchUser} from "../../../shared/hooks/tanstackQuery/usePatchUser";


interface LoginScreenProps {
  hasToken?: boolean;
}

export const LoginScreenForFBReview: FC<LoginScreenProps> = ({ hasToken }) => {
  const {data: user } = useGetUser()
  const {mutate: pacthUser} = usePatchUser({
    onSuccess: () => {
      if(hasToken) {
        FacebookLoginClient.logout(console.log)
      }
    }
  })
  const dispatch = useDispatch();
  const {onSuccess, scope} = useOnSuccessMetaConnected();


  const Title = () => {
    return hasToken ? (
      <h1 className={styles.title}>
        There are no videos in your Instagram account,
        <br />
        please add videos, log out with the button below,
        <br />
        and then continue with Facebook again
      </h1>
    ) : (
      <h1 className={styles.title}>
        Continue with Facebook to import videos
        <br />
        from your brand's Instagram
        <br />
        account and start creating widgets
      </h1>
    );
  };
  return (
    <div className={styles.container}>

        <>
          <Title />
          <div>
            {hasToken ? (
              <div
                onClick={() =>
                  pacthUser({instagramToken: ''})
                }
              >
                <FacebookLoginButton isConnected />
              </div>
            ) : (
              <FacebookLogin
                appId="2769783553336873"
                initParams={{
                  version: 'v16.0',
                }}
                onSuccess={onSuccess}
                scope={scope}
                className={styles.reset}
                loginOptions={{
                  auth_type: 'reauthorize',
                }}
              >
                {user && <FacebookLoginButton isConnected={!!user.instagram_token}/>}
              </FacebookLogin>
            )}

            {/*<LoginItem fb={true} icon={<Instagram />} name="Instagram" title="Connect" />*/}
            {/* <LoginItem icon={<TikTok />} name="Tik Tok" title="Connect" />
        <LoginItem icon={<IconUpload className={styles.icon} />} name="Upload" title="Upload" /> */}
          </div>
        </>
    </div>
  );
};
