import React, { useState, useEffect } from 'react';
import { Container, Title, Text, Group, Stack, Button, Box, useMantineTheme } from '@mantine/core';
import { IconToolsOff, IconRefresh } from '@tabler/icons-react';
import { useGetMaintenanceStatus } from '../../shared/hooks/tanstackQuery/useGetMaintenanceStatus';

const title = 'We are under Maintenance';
const message = 'We are currently performing scheduled maintenance. Please check back soon.';

export const MaintenancePage: React.FC = () => {
  const [timeRemaining, setTimeRemaining] = useState<number>(0);
  const { data } = useGetMaintenanceStatus();
  useEffect(() => {
    const updateTimer = () => {
      const now = new Date();
      const diff: number | undefined = data ? data?.endDate.getTime() - now.getTime() : undefined;
      if (diff) {
        setTimeRemaining(Math.max(0, Math.floor(diff / 1000)));
      }
    };

    updateTimer();
    const timer = setInterval(updateTimer, 1000);

    return () => clearInterval(timer);
  }, [data]);

  const formatTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const theme = useMantineTheme();
  console.log(theme.colors.whitePrimary);

  return (
    <Container
      size="md"
      style={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Stack align="center" spacing="xl" sx={{ color: theme.colors.whitePrimary }}>
        <IconToolsOff size={64} color="blue" />
        <Title order={1}>{title}</Title>
        <Text size="lg" align="center">
          {message}
        </Text>
        <Box h={50}>
          {
            <Text size="xl" weight={700}>
              {timeRemaining > 0
                ? `Time remaining: ${formatTime(timeRemaining)}`
                : timeRemaining
                ? 'Maintenance will be completed soon'
                : null}
            </Text>
          }
        </Box>
        <Group>
          <Button
            leftIcon={<IconRefresh size={20} />}
            onClick={() => (window.location.href = '/')}
            variant="outline"
          >
            Refresh Page
          </Button>
        </Group>
      </Stack>
    </Container>
  );
};
