import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkAppBlock } from 'src/api/widget/checkAppBlock';
import {WidgetBlockStatus, WidgetStatus, WidgetViewType} from 'src/components/Widget/types';
import { widgetsActions } from 'src/components/Widget/store';
import { selectActiveWidget } from 'src/components/Widget/selectors';

export interface Block {
  activate_url: string | null;
  page_type: WidgetViewType;
  preview_url: string;
  status: string;
  theme_name: string;
}
export const useCheckWidgetInstallation = (
  widgetId: string,
  setLoading: (loading: boolean) => void
) => {
  const [blockStatuses, setBlockStatuses] = useState<WidgetBlockStatus[]>([]);
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState<Block | null>(null);

  const dispatch = useDispatch();
  const currentWidget = useSelector(selectActiveWidget);


  const checkInstallation = async (callback?: () => void) => {
    setLoading(true);
    setError(false);
    try {
      await checkAppBlock(widgetId).then(async ({ data }) => {
        setBlockStatuses(data.blockStatuses);
        setData(data);
        if (data.status === 'OK') {

          if (callback) {
            callback();
          }
          setChecked(true);
          setError(false);
          return;
        } else {
          setChecked(false);
          setError(true);
        }
      });
    } catch (e) {
      setChecked(false);
      setError(true);
    }
    setLoading(false);
  };
  return {
    checkInstallation,
    error,
    checked,
    data,
    blockStatuses,
  };
};
