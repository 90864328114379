import { apiClient } from '../client';

interface GetAITaggingStatusResponse {
  "storeAITaggingStatuses": {
    "id": string,
    "userId": string,
    "createdAt": string,
    "updatedAt": string,
    "status": "Completed" | "Started",
    "isShowMessage": boolean
  },
  "countTaggedProductsToVideo": number
  countVideosWithAITaggedProducts: number
}

export const getAITaggingStatus = () => {
  return apiClient.get<GetAITaggingStatusResponse>('/bff/store-ai-tagging-status/status');
};
