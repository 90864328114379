import {useMutation} from "@tanstack/react-query";
import {queryClient} from "../../api/client";
import {queryKey} from "../query/keys";
import {putMessageAITaggingStatus} from "../../api/ai/putMessageAITaggingStatus";

export const usePutITaggingStatus = () => {
  return useMutation({
    mutationFn: putMessageAITaggingStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKey.aiTaggingStatus
      });
    }
  })
}
