import React, {useState} from 'react';
import {Box, Flex} from "@mantine/core";

import {DataRetrieval, MetaInformation, PlatformMode, ShoppingCard} from "@videopoint/widget-lib";

import {WidgetConfig, ShopifyProduct} from "@videopoint/widget-lib";
import {useSelector} from "react-redux";
import {selectActiveWidget} from "../../../../../selectors";

import classes from './ProductCardPreview.module.scss'
import {MobileView} from "../Layout/MobileView";
import {ViewManager} from "../Layout/Layout.components";
import {GetPreviewProduct, getPreviewProduct} from "../../../../../../../api/widget/getPreviewProduct";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {queryKey} from "../../../../../../../shared/query/keys";

interface ProductCardPreviewProps {
  mode: 'product-details' | 'buy-button'
}

export const ProductCardPreview: React.FC<ProductCardPreviewProps> = ({mode}) => {
  const platformMode: PlatformMode = mode === 'product-details' ? PlatformMode.productDetailsPreviewMobile : PlatformMode.buyButtonPreviewMobile


  const widget = useSelector(selectActiveWidget);
  const [viewport, setViewport] = useState<HTMLDivElement | null>(null)

  const productHandle = 'productHandle'
  const queryClient = useQueryClient()
  const fetchPreviewProduct = async () => {
    return queryClient.fetchQuery({
      queryKey: queryKey.widgetPreviewProductFn,
      staleTime: 10 * 1000,
      queryFn: () => {
        return getPreviewProduct().then((response) => response.data)
      },
    })
  }


  const {data} = useQuery({
    queryKey: queryKey.widgetPreviewProduct,
    staleTime: 10 * 1000,
    queryFn: () => {
      return getPreviewProduct().then((response) => response.data)
    },
  })

  const productName = data?.title || ''

  const dataRetrieval: DataRetrieval = {
    fetchProduct: async () => {
      const product = await  fetchPreviewProduct()
      const transformedProduct: ShopifyProduct = {
        images: product.images,
        handle: product.handle,
        url: product.url,
        id: product.id,
        price: product.price,
        title: product.title,
        description: product.description,
        variants: product.variants,
        options: product.options,
      }
      return transformedProduct
    },
  }


  const consumerWidget: WidgetConfig | undefined = widget ?  {
    ...widget,
    videoCount: 0,
  } : undefined

  const consumerWidgetProductDetails = widget?.productDetails?.filter((item) => item.name && item.isVisible).map((item) => ({
    name: item.name,
    value: item.value,
    metaInformation: item.metaInformation as MetaInformation
  })) || []

  return (
    <Box id="product-card-preview" className={classes.rootView} >
        <ViewManager mobile={
          <Flex sx={{flexGrow: 1}} justify="center">
            <MobileView getViewport={setViewport} isShowSkeleton={false}>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/*   @ts-ignore */}
                {viewport && consumerWidget && <ShoppingCard
                  widgetId={widget?.id ?? 'pseudoId'}
                  widget={consumerWidget}
                  dataRetrieval={dataRetrieval}
                  cart={undefined}
                  handle={productHandle}
                  platformMode={platformMode}
                  productDetails={consumerWidgetProductDetails}
                  name={productName}
                  viewPort={viewport}
                />}
            </MobileView>
          </Flex>
        }
        />

    </Box>
  );
};

