import React from 'react';
import { Box, Text, useMantineTheme } from '@mantine/core';

export const Unauthorized = () => {
  const theme = useMantineTheme();
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Text
        sx={{
          fontSize: 32,
          fontWeight: 600,
          marginBottom: 20,
          textAlign: 'center',
          color: theme.colors.whitePrimary,
        }}
      >
        Unauthorized <br /> Please login via Shopify
      </Text>
    </Box>
  );
};
