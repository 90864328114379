import { FC, useContext, useEffect} from 'react';
import { ActionIcon, Box, Button, Flex, Loader } from '@mantine/core';
import styles from './WidgetCreationHeader.module.scss';
import { StepsContext } from 'src/pages/widgets/new/StepsContext';
import { ReactComponent as IconChevronLeft } from '../../../shared/icons/chevron-left.svg';
import { RenameTextfield } from './RenameTextfield';
import { WidgetMenu } from './WidgetMenu';
import { useRenameWidget } from 'src/shared/hooks/useRenameWidget';
import { useSelector, useDispatch } from 'react-redux';
import { selectActiveWidget, selectActiveWidgetUpdatingStatus } from 'src/components/Widget/selectors';
import { widgetsActions } from 'src/components/Widget/store';
import { useDisclosure } from '@mantine/hooks';
import { ConfirmDeletionModal } from 'src/components/Library/ConfirmDeletionModal';
import { useNavigate } from 'react-router-dom';
import { WidgetStatus } from '../types';
import { WidgetViewType } from 'src/components/Widget/types';
import { GradientButton } from 'src/components/GradientButton/GradientButtom';
import { PublishButton } from '../Steps/Setup/Installation/Installation.steps';
import { usePublishWidget } from 'src/shared/hooks/widgetInstallationTab/usePublishWidget';
import { ExternalLink } from 'tabler-icons-react';
import { InstallationContext } from '../Steps/Setup/Installation/InstallationProvider';
import { sendLivePreviewUsed } from "../../../shared/eventLogger/eventLogger";
import {useDeleteWidget} from "../../../shared/hooks/tanstackQuery/useDeleteWidget";
import {useWidgetsList} from "../../../shared/hooks/useWidgetsList";
import {useGetWidgetsManger} from "../../../shared/hooks/tanstackQuery/useGetWidgetsManger";
import {usePostDuplicateWidget} from "../../../shared/hooks/tanstackQuery/usePostDuplicateWidget";
import {usePreviewWidget} from "../../../shared/hooks/usePreviewWidget";

interface WidgetCreationHeaderProps {
  isEditPage?: boolean;
  openInstallTab?: () => void;
}
export const WidgetCreationHeader: FC<WidgetCreationHeaderProps> = ({
  isEditPage,
  openInstallTab,
}) => {
  const activeWidgetUpdatingStatus = useSelector(selectActiveWidgetUpdatingStatus);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentStep, changeStep } = useContext(StepsContext);
  const { data, loading, error: checkInstallationError, checked } = useContext(InstallationContext);
  const activeWidget = useSelector(selectActiveWidget);

  const { value, onChange, onCancel, onConfirm, disableConfirmButton, error, onRename, renaming } =
    useRenameWidget();

  const [deleteModal, setDeleteModal] = useDisclosure(false);
  const goBack = () => {
    if (isEditPage) {
      dispatch(widgetsActions.setActiveWidget(null));
      navigate('/widgets', { replace: true });
      return;
    }
    changeStep(currentStep - 1);
  };


  const {mutate: deleteWidget}  = useDeleteWidget({
    onSuccess: () => {
      setDeleteModal.close();
      navigate('/widgets');
    }
  })

  const onDelete = () => {
    if (!activeWidget) return;
    deleteWidget({id: activeWidget.id})
  };

  const onSuccessDuplication = ({id}: {id: string}) => {
    navigate(`/widgets/edit/${id}`);
  }

  const {mutate: duplicateWidget} = usePostDuplicateWidget({
    onSuccess: onSuccessDuplication
  })
  const onDuplicate = () => {
    if (!activeWidget) return;
    duplicateWidget({widgetId: activeWidget.id})
  };

  const { publishingHandler, progress } = usePublishWidget();
  const {onPreview: onPreviewHandler, isLoading: isPreviewLoading} = usePreviewWidget()

  const previewHandler = () => {
    onPreviewHandler({
      id: activeWidget?.id || '',
      name: activeWidget?.name || ''
    }, {ifError: []})
  };
  useEffect(() => {
    if (isEditPage) {
      changeStep(3);
    }
  }, [isEditPage]);
  const isShowLoader = activeWidgetUpdatingStatus === 'loading' || loading;

  return (
    <div id="single-widget-header" className={styles.container}>
      <Flex align="center">
        <ActionIcon variant="transparent" onClick={goBack}>
          <IconChevronLeft />
        </ActionIcon>
        {renaming ? (
          <RenameTextfield
            value={value}
            onChange={onChange}
            onCancel={onCancel}
            onConfirm={onConfirm}
            disableConfirmButton={disableConfirmButton}
            error={error}
          />
        ) : (
          <>
            <WidgetMenu
              widgetName={value}
              onRename={onRename}
              onDelete={setDeleteModal.open}
              onDuplicate={onDuplicate}
              disableCopyBtn={currentStep < 3 && !isEditPage}
              disableDeleteBtn={currentStep < 3 && !isEditPage}
            />
            <ConfirmDeletionModal
              onCancel={setDeleteModal.close}
              onConfirm={onDelete}
              open={deleteModal}
              onClose={setDeleteModal.close}
              bodyText="Are you sure you want to remove widget?"
              title="Remove widget"
            />
          </>
        )}
      </Flex>
      {currentStep < 3 ? null : isShowLoader ? (
        <Loader variant="bars" color="greyDark.0" />
      ) : (
        <Box>
          {checked && !checkInstallationError ? (
            <Flex>
              <Button
                variant="default"
                className={styles.previewBtn}
                leftIcon={isPreviewLoading ? <Loader size={15} /> : <ExternalLink size={15} />}
                mr={14}
                onClick={previewHandler}
              >
                Live Preview
              </Button>
              {activeWidget &&
                <PublishButton handler={(unpublish) => publishingHandler(activeWidget.id, unpublish)} progress={progress} />
              }
            </Flex>
          ) : (
            openInstallTab && <GradientButton onClick={openInstallTab}>Install</GradientButton>
          )}
        </Box>
      )}
    </div>
  );
};
