import {useMutation} from "@tanstack/react-query";
import {patchProductsToVideo} from "../../api/video/products/patchProductsToVideo";
import {useCallback} from "react";
import {queryClient} from "../../api/client";
import {queryKey} from "../query/keys";
import {QueryVideoFilter} from "../../api/video/getVideos";
import {checkAndRefetchOnboardingData} from "../../api/onboarding/checkAndRefetchOnboardingData";

export const useTagProductsToVideos = () => {
  const onSuccess = useCallback((_: unknown, variables: {id: string }) => {
    queryClient.refetchQueries({
      queryKey: queryKey.videos({
        filter: QueryVideoFilter.INBOX,
      })
    })
    queryClient.refetchQueries({
      queryKey: queryKey.videos({
        filter: QueryVideoFilter.SHOPPABLE,
      })
    })
    queryClient.refetchQueries({
      queryKey: queryKey.videos({
        filter: QueryVideoFilter.INSTAGRAM,
      })
    })
    queryClient.refetchQueries({
      queryKey: queryKey.videos({
        filter: QueryVideoFilter.UPLOAD,
      })
    })
    queryClient.refetchQueries({
      queryKey: queryKey.videos({
        filter: QueryVideoFilter.ALL,
      })
    })
    queryClient.refetchQueries({
      queryKey: queryKey.taggedProductsWithVideoId({
        videoId: variables.id,
      })
    })
    queryClient.refetchQueries({
      queryKey: queryKey.videosCountPerFilter
    })
    checkAndRefetchOnboardingData()
  },[])

  return useMutation({
    mutationFn: async (data:{
      id: string;
      toAdd: string[];
      toDelete: string[];
    }) => patchProductsToVideo(data),
    onSuccess,
  })
}
